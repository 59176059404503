.labelTitle {
    color: #ffffff;
    font-weight: bold;
}

.labelText {
    color: #ffffff;
}

.sectionTitle {
    color: #ffffff;
    font-weight: bold;
    font-size: xx-large;
}

.form-check-input:checked {
    background-color: #168f84 !important;
    border-color: black !important;
}